import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { Form } from '@formio/react';

export const JsonForm = ({ slice, index }) => {
  const [jsonData, setJsonData] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);

  const slicePrimary = slice.primary || {};
  const {json_config} = slicePrimary;
  console.log('json_config: ', typeof(json_config))

  const isFirst = index === 0;

  React.useEffect(() => {
    try {
      // console.log('json_config: ', json_config);
      const j = JSON.parse(json_config);
      setJsonData(j);
    } catch (e) {
      console.error('e: ', e);
    }
  },[json_config]);

  const onSubmit = async(params) => {
    console.log('onSubmit data: ', params);
    setSubmitted(true);
    const {hook} = jsonData;
    if (hook) {
      const {url, method,token} = hook;
      let hookUrl = url;
      try {
        const options = {
          method, // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow',
          // credentials: 'include',
          headers: {
            // "Access-Control-Allow-Origin": "*", // This had to be removed to fix the CORS error
            "content-type": "text/plain",
          },
        };
        if (token) {
          const bearer = `Bearer ${token}`;
          options.headers['Authorization'] = bearer;
          options.headers["Access-Control-Allow-Credentials"] = true;
        }
        if ( 'GET' === method ) {
          hookUrl += '?' + ( new URLSearchParams( params.data ) ).toString();
          // console.log('url: ', url);
        } else {
          // console.log(`send to: ${url}`);
          options.body = JSON.stringify( params.data );
        }
        // console.log('options: ', options);
        // console.log('url: ', hookUrl);
        const r = await fetch(hookUrl, options);
        console.info('submitted ok');
      } catch(e) {
        console.log('e: ', e);
      }
    }
  };

  if (!jsonData) return <></>;

  if (submitted) {
    const {submitted} = jsonData;
    const {text} = submitted;
    return (
      <div className={"container" + " " + (isFirst ? "py-5" : "pb-5")}>
        {text}
      </div>
    );
  }

  return (
    <div className={"container" + " " + (isFirst ? "py-5" : "pb-5")}>
      <Form 
        // src={"https://examples.form.io/example"}
        form={jsonData.config}
        onSubmit={onSubmit}
      />
    </div>
  )
}

