import * as React from 'react'
import { graphql } from 'gatsby'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { useLocation } from '@reach/router';
import translate from '../utils/translator'
import moment from 'moment';

import {convertPrismicRest, convertPrismicStory} from '../utils/utils'

const ViewMode = {
  Loading: 'loading',
  Day: 'day',
  Week: 'week',
}
const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]
const weekData = [
  {code: 'week', name: 'Woche',},
  {code: 'monday',name: 'Montag',},
  {code: 'tuesday',name: 'Dienstag',},
  {code: 'wednesday',name: 'Mittwoch',},
  {code: 'thursday',name: 'Donnerstag',},
  {code: 'friday',name: 'Freitag',},
]
const weekDays = {}
weekData.forEach(item => {
  weekDays[item.code] = item.name
})

export const WeekMenu = ({ slice, context }) => {
  console.log('slice: ', slice)
  console.log('context: ', context)
  const {id, items} = slice

  const [rests, setRests] = React.useState([])
  const [story, setStory] = React.useState([])

  const [state, setState] = React.useState({
    week: 1,
    dates: '',
    day: '',
    mode: ViewMode.Loading,
  });
  React.useEffect(() => {
    // Runs ONCE after initial rendering
    const week = context.weekNumber-1
    const nowDay = moment().startOf('year').add(week, 'weeks')
    const startWeek = nowDay.startOf('isoWeek').format('DD.MM')
    const endWeek = nowDay.endOf('isoWeek').format('DD.MM.YYYY')
    const dates = `${startWeek} - ${endWeek}`
    // setState({day: '', week, dates, mode: ViewMode.Loading})
    //
    const currentWeekDay = moment().isoWeekday()
    console.log("currentWeekDay",currentWeekDay)
    const _rests = slice.items.map(item => {
      return convertPrismicRest({item, rests: context.rests})
    }).sort((a,b) => {
      if (a.featured == b.featured === true) return 0
      if (a.featured === true) return -1
      return 1
    })
    console.log('sorted: ', _rests)
    setRests(_rests)
    //
    const _story = slice.primary?.link_to_story?.document
    if (_story && _story.type === 'stories') {
      console.log('story uid: ', _story.uid)
      const _sBody = _story.data?.body || []
      console.log('story data: ', _sBody)
      const _stories = _sBody.find(item => item.slice_type === 'story')
      console.log('_stories: ', _stories)
      if (_stories && _stories.items) {
        const _s = _stories.items.map(item => {
          return convertPrismicStory({item, rests: context.rests})
        })
        setStory(_s)
      }
    }
    //
    const hash = getHash()
    console.log('hash: ', hash)
    let mode = ViewMode.Week, day = ''
    if (hash && days.includes(hash)) {
      mode = ViewMode.Day
      day = hash
    }
    moment.locale('en')
    const d = moment()
    const wd = d.format('dddd').toLowerCase();
    console.log(`wd: ${wd}`)
    if (days.includes(wd) && (window.innerWidth <= 960)) {
      // isMobile
      mode = ViewMode.Day
      day = wd
    }
    setState(prevState => { return {...prevState, mode, day, week, dates} })

  }, [])

  const onChangeCombo = (event) => {
    // console.log('event: ', event)
    const {target} = event
    const {name, value} = target
    console.log('combo: %s value: %s', name, value)
    if (value === 'week') {
      window.location.hash=''
      setState(prevState => {
        return {...prevState, mode: ViewMode.Week, day: ''}
      })
    } else {
      window.location.hash=`${value}`
      setState(prevState => {
        return {...prevState, mode: ViewMode.Day, day: value}
      })
    }
  }

  const SingleCell = ({cell}) => {
    const {date,day,code,name, phone, img, location, singleCell} = cell
    return (
      <>
        {/** first cell with name and logo */}
        <div className="col-2 p-4 border-bottom border-end d-flex flex-column align-items-center">         
          <img src={img} style={{width:"100%",maxWidth:"120px",maxHeight:"96px",objectFit:"contain"}} className=""></img>
          <div className="mt-3 row gx-3">
				  	{location &&
              <div className="col">
				  		  <a href={location} target="_blank" type="button" className="rounded-circle bg-info p-2 bg-opacity-10">
                  <img className="icon-info" height="24"   width="24" src={"/icons/location.svg"} alt=""/>
                </a>
              </div>
            }
				  	{phone &&
              <div className="col">
                <a href={"tel:"+phone} target="_blank" type="button" className="rounded-circle bg-success p-2 bg-opacity-10">
                  <img className="icon-success" height="24"   width="24" src={"/icons/phone.svg"} alt=""/>
                </a>
              </div>
				  	}
				  </div>
        </div>
        {/** data */}
        <div className="col-10 d-flex flex-column justify-content-center p-4 border-bottom" dangerouslySetInnerHTML={{__html: singleCell}} />
      </>
    )
  }

  const WeekCells = ({rest}) => {
    const {date,day,code,name, phone, img, url, location, weekData, singleCell} = rest
    console.log("singleCell ",singleCell)
    return (
      <>
        {/** first cell with name and logo */}
        <div className="col-2 p-4 border-bottom border-end d-flex flex-column align-items-center">
          <a href={url}>
            <h5 className="text-center text-dark">{name}</h5>
          </a>
          <a href={url}>
            <img src={img} style={{width:"100%",maxWidth:"120px",maxHeight:"96px",objectFit:"contain"}} className="" />
          </a>
          <div className="mt-3 row gx-3">
				  	{location &&
              <div className="col">
				  		  <a href={location} target="_blank" type="button" className="rounded-circle bg-info p-2 bg-opacity-10">
                  <img className="icon-info" height="24"   width="24" src={"/icons/location.svg"} alt=""/>
                </a>
              </div>
            }
				  	{phone &&
              <div className="col">
                <a href={"tel:"+phone} target="_blank" type="button" className="rounded-circle bg-success p-2 bg-opacity-10">
                  <img className="icon-success" height="24"   width="24" src={"/icons/phone.svg"} alt=""/>
                </a>
              </div>
				  	}
				  </div>
        </div>
        {/** data */}
        <div className="col-10 d-flex flex-column border-bottom">
          {(singleCell && singleCell != "<p></p>") &&
            <div className="flex-fill d-flex flex-column justify-content-center p-4 single-cell" dangerouslySetInnerHTML={{__html: singleCell}} />
          }
          {!rest.isSingle &&
          <div className="flex-fill row m-0 w-100">
            {days.map((item, index) => (
              <div key={index} className={"col p-4 text-break" + " " + ((days.length-1 !== index) ? "border-end" : "")} dangerouslySetInnerHTML={{__html: weekData[item]}}/>
            ))}
          </div>
          }
        </div>
      </>
    )
  }

  const RestaurantWeekMenu = ({rest}) => {
    //
    return (
      /*<>
        {rest.isSingle ? 
          <SingleCell cell={rest} /> :
          <WeekCells week={rest} />
        }
      </>*/
      <WeekCells rest={rest} />
    )
  }
  // render mode
  const renderLoading = () => (
    <p>Loading mode</p>
  )
  const RenderDayRest = ({rest}) => {
    /*const day = rest.isSingle ?
      rest.singleCell :
      rest.weekData[state.day]*/
    const day = rest.weekData[state.day]

    if (!day) return (<></>)

    const {name, phone, img, url, location, weekData, singleCell, isAllDishes} = rest
    
    return (
      <>
        {/** first cell with name and logo */}
        <div className="col-12 col-sm-4 col-md-3 col-xl-2 p-4 border-sm-bottom border-sm-end text-center">
          <a href={url}>
            <h5 className="d-none d-sm-block text-dark">{name}</h5>
          </a>
          <div className="d-flex flex-row flex-sm-column align-items-start align-items-sm-center">
            <a href={url}>         
              <img src={img} style={{width:"100%",maxWidth:"120px",maxHeight:"96px",objectFit:"contain"}} className="" />
            </a>
            <div className="ms-3 ms-sm-0">
              <a href={url}>
                <h5 className="d-block d-sm-none text-dark">{name}</h5>
              </a>
              <div className="mt-3 row gx-3">
				      	{location &&
                  <div className="col">
				      		  <a href={location} target="_blank" type="button" className="rounded-circle bg-info p-2 bg-opacity-10">
                      <img className="icon-info" height="24"   width="24" src={"/icons/location.svg"} alt=""/>
                    </a>
                  </div>
                }
				      	{phone &&
                  <div className="col">
                    <a href={"tel:"+phone} target="_blank" type="button" className="rounded-circle bg-success p-2 bg-opacity-10">
                      <img className="icon-success" height="24"   width="24" src={"/icons/phone.svg"} alt=""/>
                    </a>
                  </div>
				      	}
				      </div>
            </div>
          </div>
        </div>
        {/** data */}
        <div className="col-12 col-sm-8 col-md-9 col-xl-10 px-4 pb-4 p-sm-4 border-bottom">
          {(singleCell && singleCell != "<p></p>") &&
            <div className="flex-fill d-flex flex-column justify-content-center p-4 single-cell" dangerouslySetInnerHTML={{__html: singleCell}} />
          }
          {isAllDishes?
            <div className="p-4">
              {days.map((item, index) => (
                <div key={index} className={"text-break" + " " + ((days.length-1 !== index) ? "border-end" : "")} dangerouslySetInnerHTML={{__html: weekData[item]}}/>
              ))}
            </div>
          :
            <div dangerouslySetInnerHTML={{__html: day}} />
          }
        </div>
      </>
    )
  }
  // see fields: src/utils/utils.js -> outRest
  const renderDay = () => {
    const dayName = weekDays[state.day]

    return (
      <div className="mt-3" style={{overflowX:"scroll"}}>
        <div className="bg-white rounded-3 overflow-hidden text-break" style={{fontSize:"14px"}}>
          <div className="row g-0">
            <div className="col-6 col-sm-4 col-md-3 col-xl-2 p-4 d-flex flex-column justify-content-center border-bottom border-end">
              <h6 className="mb-0">Datum<br/>{state.dates}</h6>
            </div>
            <div className="col-6 col-sm-8 col-md-9 col-xl-10 p-4 d-flex flex-column justify-content-center border-bottom">
              <div className="h5 mb-0 text-uppercase align-middle">{dayName}</div>
            </div>
          </div>
          {/** DATA */}
          <>
            {rests.map(item => (
              <div key={item.code}  className="row g-0">
                <RenderDayRest rest={item} />
              </div>
            ))}
          </>
        </div>
      </div>
    )
  }
  // see fields: src/utils/utils.js -> outRest
  const renderWeek = () => (
    <div className="mt-3" style={{overflowX:"scroll"}}>
      <div className="bg-white rounded-3 overflow-hidden border" style={{fontSize:"14px",minWidth:"1296px"}}>
        <div className="row g-0">
          {/** HEADER */}
          {/** first cell is date */}
          <div className="col-2 p-4 d-flex flex-column justify-content-center border-bottom border-end">
            <h6 className="mb-0">Datum<br/>{state.dates}</h6>
          </div>
          {/** week day names */}
          {days.map((item, index) => (
            <div key={index} className={"col-2 p-4 d-flex flex-column justify-content-center border-bottom " + ((days.length-1 !== index) ? "border-end" : "")}  >
              <div className="h5 mb-0 text-uppercase align-middle">{weekDays[item]}</div>
            </div>
          ))}
        </div>
        {/** DATA */}
        <>
          {rests.map(item => (
            <div className={"row g-0"} style={item.featured ? {backgroundColor:"#61dd9308"} : {}} key={item.code}>
              <RestaurantWeekMenu rest={item} />
            </div>
          ))}
        </>
      </div>
    </div>
  )
  const renderMap = {
    [ViewMode.Loading] : renderLoading,
    [ViewMode.Week] : renderWeek,
    [ViewMode.Day] : renderDay,
  }

  console.log('render mode: ', state.mode)
  const Component = renderMap[state.mode]

  const RenderStory = () => {
    console.log('RenderStory: ', story)

    return (
      <Gallery>
        {story.map((item, index) => (
          // see fields: src/utils/utils.js -> convertPrismicStory.outRest
          <div key={index} className={"col-6 mt-3 mt-md-0 col-md-auto"}>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center align-items-center story" style={{height:"70px",width:"70px",aspectRatio:"1",borderRadius:"50%"}}>
                <Item original={item.img} thumbnail={item.img} width={2400} height={2400} >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={item.img} className="image-cover" style={{borderRadius:"50%"}} />
                  )}
                </Item>
				      </div>
              <p className="h5 text-truncate ms-3 mb-0" style={{maxWidth:"96px",fontSize:"16px",fontWeight:"400"}}>{item.name}</p>
            </div>
          </div>
        ))}
      </Gallery>
    )
  }

  return (
    <div  className="bg-light wide border-bottom">
      <div className="container-fluid container-xxl py-5">
        <div className="bg-white rounded-3 overflow-hidden mb-4 testimonial-group">
          <div className="p-4 py-5">
            <h6 className="mb-4">{translate('stories_recommendations')}</h6>
            <div className="row gx-5">
              <RenderStory />
            </div>
          </div>
        </div>
        <RenderCombo
          name={'Select view mode'}
          options={weekData}
          value={state.day}
          onChange={onChangeCombo}
        />
        <Component />
      </div>
    </div>
  )
}
//------
const RenderCombo = ({name, options, value, onChange}) => (
  <div className="d-flex justify-content-center">
    <select name={name} value={value} onChange={onChange} className="form-select w-auto" aria-label="Default select example">
      {options.map((item, index) => {
        return (
          <option
            key={index}
            value={item.code}
          >{item.name}</option>)
      })}
    </select>
  </div>
)
const getHash = () => {
  const search = window.location.search
  // console.log(search)
  const _hash = window.location.hash
  const hash = _hash ? _hash.substring(1) : ''
  return hash
}

export const query = graphql`
  fragment MenuDayMainSlice on PrismicMenuDayDataBodyMainSlice {
    id
    slice_label
    slice_type
    items {
      date
      day_or_week
      featured
      restaurant
      single_cell {
        html
      }
      single_day_menu {
        html
      }
      monday_menu {
        html
      }
      tuesday_menu {
        html
      }
      wednesday_menu {
        html
      }
      thursday_menu {
        html
      }
      friday_menu {
        html
      }
    }
    primary {
      link_to_story {
        id
        link_type
        document {
          ... on PrismicStories {
            id
            type
            uid
            data {
              body {
                ... on PrismicStoriesDataBodyStory {
                  id
                  slice_type
                  items {
                    date
                    restaurant
                    image_url
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
