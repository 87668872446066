import { EventInfo } from './EventInfo'
import { MainCategory } from './MainCategory'
import { MainWeeklyCard } from './MainWeeklyCard'
import { MainRestourants } from './MainRestourants'
import { VideoblogInfo } from './VideoblogInfo'
import { RestaurantDetail } from './RestaurantDetail'
import { RestaurantDetailMenu } from './RestaurantDetailMenu'
import { MainEvents } from './MainEvents'
import { MainVideoblog } from './MainVideoblog'
import { MainAboutUs } from './MainAboutUs'
import { PageCover } from './PageCover'
import { OneLineTitleAndText } from './OneLineTitleAndText'
import { VideoAndText } from './VideoAndText'
import { OrderedListWithDesc } from './OrderedListWithDesc'
import { FeaturesTwoCols } from './FeaturesTwoCols'
import { FeaturesThreeCols } from './FeaturesThreeCols'
import { CallToAction } from './CallToAction'
import { AccordionFaq } from './AccordionFaq'
import { OurTeam } from './OurTeam'
import { TitleAndText } from './TitleAndText'
import { EmbeddedHtml } from './EmbeddedHtml'
import { EmbeddedHtmlTwo } from './EmbeddedHtmlTwo'
import { WeekMenu } from './WeekMenu'
import { VacancyInfo } from './VacancyInfo'
import { RentalInfo } from './RentalInfo'
import { ContactForm } from './ContactForm'
import { RecipeInfo } from './RecipeInfo'
import { BlogInfo } from './BlogInfo'
import { MainBlog } from './MainBlog'
import { CouponsForm } from './CouponsForm'
import { JsonForm } from './JsonForm'

export const components = {
	json_form: JsonForm,
  event_info: EventInfo,
  main_category: MainCategory,
  main_weekly_card: MainWeeklyCard,
  main_restourants: MainRestourants,
  videoblog_info: VideoblogInfo,
  restaurant_detail: RestaurantDetail,
  restaurant_detail_menu: RestaurantDetailMenu,
  menu: RestaurantDetailMenu,
  main_events: MainEvents,
  main_videoblog: MainVideoblog,
	main_about_us: MainAboutUs,
	page_cover: PageCover,
	one_line_title_and_text: OneLineTitleAndText,
	video_and_text: VideoAndText,
	ordered_list_with_desc: OrderedListWithDesc,
	features_two_cols: FeaturesTwoCols,
	features_three_cols: FeaturesThreeCols,
	call_to_action: CallToAction,
	accordion_faq: AccordionFaq,
	our_team: OurTeam,
	title_and_text: TitleAndText,
	embedded_html: EmbeddedHtml,
	embedded_html_two: EmbeddedHtmlTwo,
	main_slice: WeekMenu,
	vacancy_info: VacancyInfo,
	rental_info: RentalInfo,
	contact_form: ContactForm,
	recipe_info: RecipeInfo,
	blog_info: BlogInfo,
	main_blog: MainBlog,
	coupons_form: CouponsForm,
}
