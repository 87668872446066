import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import { asText } from '@prismicio/helpers'

import translate from '../utils/translator'

export const MainWeeklyCard = ({ slice }) => {
  console.log('MainWeeklyCard slice: ', slice)
  const {id, primary, slice_type} = slice
  if (slice_type !== 'main_weekly_card') return null
  const {bg_secondary, title1, desc1, button, image} = primary

  return(
    <div className={"py-4" + " " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{title1.text}</h2>
        <div className="row mt-4">
          <div className="col-12 col-lg-6">
            <div style={{textAlign:"justify"}}><PrismicText field={desc1.richText} /></div>
            <div className="mt-4">
              <a href={"/wochenkarte/index.html"} type="button" className="btn btn-primary">{translate('btn_weekly_menu')}</a>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <img className="image-contain" src={image.url} alt={image.alt} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainWeeklyCard on PrismicMainpageDataBodyMainWeeklyCard {
    id
    slice_type
    primary {
      bg_secondary
      title1 {
        richText
        text
      }
      image {
        url
        alt
      }
      desc1 {
        richText
      }
      button {
        id
        lang
      }
    }
  }
`
