import * as React from 'react'
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';
import { PrismicRichText } from '@prismicio/react'

export const AccordionFaq = ({ slice }) => {
  const {bg_secondary, title} = slice.primary
  const faq = slice.items.map((item, index) => {
    return (
      <Accordion.Item eventKey={index} key={index} >
        <Accordion.Header>{item.faq_title}</Accordion.Header>
        <Accordion.Body>
          <PrismicRichText field={item.faq_text.richText} />
        </Accordion.Body>
      </Accordion.Item>
    )
  })

  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center">{title}</h2>
        <div className="mt-4">
          <Accordion>
            {faq}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyAccordionFaq on PrismicPageDataBodyAccordionFaq {
    primary {
      bg_secondary
      title
    }
    items {
      faq_title
      faq_text {
        richText
      }
    }
  }
`
