import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import {Events} from '../components/Events'
import moment from 'moment';

import translate from '../utils/translator'

export const MainEvents = ({ slice, context }) => {
  console.log('events: ', context.events)

  const [events,setEvents] = useState([])
  
  useEffect( ()=> {
    const now = moment().utc()
    const _sorted = docs.map(item => {
      // console.log('item: ', item)
      item.data.startTime = moment(item.data.start_time).unix();
      item.data.finishTime = moment(item.data.finish_time).unix();
      const finishUtc = moment(item.data.finish_time).tz("Europe/Berlin")
      const isOver = now.isAfter(finishUtc)
      item.data.isOver = isOver;
      return item;
    }).sort((a,b) => {
      return a.data.startTime - b.data.startTime;
    });
    const _before = _sorted.filter(el => el.data.isOver === false);
    // const _after = _sorted.filter(el => el.data.isOver === true).reverse();
    const _after = _sorted.filter(el => el.data.isOver === true).sort((a,b) => {
      return b.data.startTime - a.data.startTime;
    });
    // console.table(_sorted);
    // _after.find((el, index) => {
    //   console.log(`start:${el.data.start_time} <:> ${el.data.finish_time}`);
    //   if (index > 9) return true;
    // });
    const _all = [..._before, ..._after];
    const filtered = _all.slice(0, 3);
    setEvents(filtered)
  }, [context.events])
  
  const docs = context.events
  docs.events = events
  console.log('events: ', events)
  
  return (
    <div className={"py-4" + " " + (slice.primary.bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{slice.primary.title1.text}</h2>
        <h5 className="text-center text-secondary">{slice.primary.desc1.text}</h5>
        <Events docs={docs} />
        <div className="mt-5 text-center">
          <a href="/events" className="btn btn-primary">{translate('btn_show_all_events')}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainEvents on PrismicMainpageDataBodyMainEvents {
    id
    slice_type
    primary {
      bg_secondary
      title1 {
        text
      }
      desc1 {
        text
      }
    }
  }
`
