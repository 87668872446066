import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const OneLineTitleAndText = ({ slice }) => {
  const {bg_secondary, title, text} = slice.primary

  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <div className="row mt-4">
          <div className="col-12 col-lg-3">
            <h2 className="text-center text-lg-start">{title}</h2>
          </div>
          <div className="col-12 col-lg-9 mt-4 mt-lg-0">
            <div style={{textAlign:"justify"}}><PrismicRichText field={text.richText} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyOneLineTitleAndText on PrismicPageDataBodyOneLineTitleAndText {
    primary {
      bg_secondary
      title
      text {
        richText
      }
    }
  }
`
