import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const OrderedListWithDesc = ({ slice }) => {
  const {bg_secondary, title} = slice.primary
  console.log(slice)
  const list = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12">
        <h4 className="">{item.list_title.text}</h4>
		  	<PrismicRichText field={item.list_desc.richText} />
		  </div>
    )
  })
  
  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center">{title}</h2>
        <div className="mt-0 row gy-4">
          {list}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyOrderedListWithDesc on PrismicPageDataBodyOrderedListWithDesc {
    primary {
      bg_secondary
      title
    }
    items {
      list_title {
        text
        richText
      }
      list_desc {
        richText
      }
    }
  }
`
