import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const MainAboutUs = ({ slice }) => {
  console.log('MainAboutUs slice: ', slice)
  const {id, primary, slice_type} = slice
  if (slice_type !== 'main_about_us') return null
  const {bg_secondary, title2, text} = primary

  return(
    <div className={"py-4" + " " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <div className="row mt-4">
          <div className="col-12 col-lg-3 col-xl-2">
            <h2 className="text-center text-primary text-lg-start">{title2}</h2>
          </div>
          <div className="col-12 col-lg-9 col-xl-10 mt-4 mt-lg-0">
            <div style={{textAlign:"justify"}}><PrismicRichText field={text.richText} /></div>
          </div>
        </div>
      </div>
    </div>
)}

export const query = graphql`
  fragment MainpageMainAboutUs on PrismicMainpageDataBodyMainAboutUs {
    id
    slice_type
    primary {
      bg_secondary
      title2
      text {
        richText
      }
    }
  }
`
