import * as React from 'react'
import { graphql } from 'gatsby'
import { BlogPosts } from '../components/BlogPosts'

import translate from '../utils/translator'

export const MainBlog = ({ slice, context }) => {
  console.log('blogs: ', context.blogs)
  const docs = {
    nodes: context.blogs,
  }

  return (
    <div className={"py-4" + " " + (slice.primary.bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{slice.primary.title1.text}</h2>
        <h5 className="text-center text-secondary">{slice.primary.desc1.text}</h5>
        <div className="mt-5">
          <BlogPosts docs={docs} />
        </div>
        <div className="mt-5 text-center">
          <a href="/blog" className="btn btn-primary">{translate('btn_show_all_blogs')}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainBlog on PrismicMainpageDataBodyMainBlog {
    id
    slice_type
    primary {
      bg_secondary
      title1 {
        text
      }
      desc1 {
        text
      }
    }
  }
`
