import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import ReactPlayer from 'react-player'

export const VideoAndText = ({ slice }) => {
  const {bg_secondary, title, text,video_url} = slice.primary

  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5 text-center">
        <h2 className="">{title}</h2>
        <div><PrismicRichText field={text.richText} /></div>
        <div className="bg-dark ar16-9 d-flex justify-content-center align-items-center">
          <ReactPlayer url={video_url.embed_url} width="100%" height="100%"/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyVideoAndText on PrismicPageDataBodyVideoAndText {
    primary {
      bg_secondary
      title
      text {
        richText
      }
      video_url {
        embed_url
      }
    }
  }
`
