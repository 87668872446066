import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import ReactPlayer from 'react-player'

export const BlogInfo = ({ slice, context }) => {
  console.log('BlogInfo slice: ', slice)

  const cntx = context
  const {title} = cntx

  const {text, img} = slice.primary

  return (
    <div>
      <div className="bg-light" style={{backgroundImage:"url(" + img.url + ")",backgroundAttachment:"fixed",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000a0",backdropFilter:"blur(4px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{title}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="mt-4">
          <img className="image-contain" style={{maxHeight:"640px"}} height="" width="" src={img.url} alt={img.alt} title={img.alt}/>
          <div className="mt-4">
            <PrismicRichText field={text.richText} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicBlogItemBlogInfo on PrismicBlogItemDataBodyBlogInfo {
    id
    slice_type
    primary {
      img {
        url
        alt
      }
      text {
        richText
      }
    }
  }
`
