import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ReactPlayer from 'react-player'

import translate from '../utils/translator'
import SimpleContactForm from '../components/SimpleContactForm';

export const VacancyInfo = ({ slice, context }) => {
  console.log('VacancyInfo slice: ', slice)
  // const {id, primary, slice_type} = slice;
  if (!slice.slice_type === 'vacancy_info') return null;
  const {title, restLink, titleSectionImage} = context
  const {uid, document} = restLink
  const _restLink = `/restaurant/${uid}`
  const restDetail = document?.data?.body?.find(item => item.slice_type === 'restaurant_detail')
  const restName = restDetail?.primary?.rest_name
  const restLogo = restDetail?.primary?.logo.url
  const {description, salary, email, phone, address, coordinates, video_url} = slice.primary
  const isInfo = phone || email

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }  

  return (
    <div>
      <div className="bg-light" style={{backgroundImage:"url(" + titleSectionImage.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <div className="py-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{title}</h1>
          </div>
        </div>
      </div>    
      <div className="container py-5 ">
      <a href="/stellenanzeigen-gastronomie-sindelfingen/" className="link-back p-2 ps-0 text-decoration-none"><img className="me-2 details-icon" height="28" width="28" src={`/icons/back.svg`}/>{translate('back')}</a>
        <div className="pt-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="">{context.title}</h2>
              <h5 className="">{salary}</h5>
              <div className="mt-4">
                <PrismicRichText field={description.richText} />
              </div>
              {video_url && 
                <div className="bg-dark ar16-9 d-flex justify-content-center align-items-center">
                  <ReactPlayer url={video_url.embed_url} width="100%" height="100%"/>
                </div>
              }
              {(address || coordinates) &&
                <div className="mt-5">
                  <h5>{translate('location')}</h5>
                  {address && (
                    <a
                      className="text-decoration-none text-secondary d-flex align-items-center"
                      href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude +   "," + coordinates.longitude) : "javascript:void(0);"}
                      target="_blank"
                    >
                      <img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`} alt=""/>
                      {address}
                    </a>
                  )}
                  {coordinates &&
                    <div className="mt-2">
                      <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
                        <GoogleMap
                          mapContainerStyle={{width:"100%",height:"400px"}}
                          center={{lat:coordinates.latitude,lng:coordinates.longitude}}
                          zoom={17}
                          options={{fullscreenControl:false,mapTypeControl:false,streetViewControl:false,styles:[{"featureType":"poi","stylers":[{"visibility":"off"}]}]}}
                        >
                          <Marker
                            position={{
                              lat: coordinates.latitude,
                              lng: coordinates.longitude
                            }}                     
                          >
                          </Marker>
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  }
                </div>
              }
            </div>
            <div className="col-lg-4">
              <div className="rounded-3 bg-light">
                {isInfo &&
                  <div className="p-4">
                    {restName && 
                      <div className="d-flex align-items-center mb-4">
                        <a href={_restLink}><img className="rounded-circle bg-light position-relative" style={{height:"48px",width:"48px"}} src={restLogo ? restLogo : "/default-rest-logo.png"}  alt='' /></a>
                        <a href={_restLink} className="h5 text-decoration-none text-body mb-0 ms-2">{restName}</a>
                      </div>
                    }
                    {/*<h6 className="text-center">Information</h6>*/}
                    <div className="mt-3 fw-500 fs-0875">
                      {address && 
                        <><a className="text-decoration-none d-flex align-items-center" href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude + "," +    coordinates.longitude) : "javascript:void(0);"} target="_blank"><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`}/>  {address}</a><hr/></>
                      }
                      {email && 
                        <><a className="text-decoration-none d-flex" href={"mailto:" + email}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/email.svg`}/>{email}</a><hr/></>
                      }
                      {phone && 
                        <><a className="text-decoration-none d-flex" href={"tel:" + phone}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/phone.svg`}/>{phone}</a><hr/></>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="mt-4 p-4 sticky-top rounded-3 border overflow-hidden">
                <SimpleContactForm formId={"sifi-eats-simple-form"} pageUrl={pageUrl}/>
              </div>            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicCareersItemVacancyInfo on PrismicCareersItemDataBodyVacancyInfo {
    id
    slice_type
    slice_label
    primary {
      salary
      description {
        richText
        text
      }
      email
      phone
      address
      coordinates {
        latitude
        longitude
      }
      video_url {
        embed_url
      }
    }
  }
`
