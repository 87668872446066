import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import { asText } from '@prismicio/helpers'

const renderCategory = ({item, index}) => {
  console.log('renderCategory item: ', item)
  const {code, name, image} = item

  return (
    <div className="col-12 col-md-4" key={index}>
      <div className="overflow-hidden rounded-3 bg-dark h-100 d-flex flex-column" style={{aspectRatio:"3/2"}}>
        <a href={`/search/?cats=${code}`} className="position-relative w-100 h-100">
          {image.url && <img className="image-cover" src={image.url} alt={"Titelfoto der Kategorie"}/>}
          <div className="w-100 h-100 position-absolute" style={{top:"0px",background:"linear-gradient(180deg, rgba(52,53,59,0) 50%, rgba(0,0,0,.75) 100%)"}}/>
          <h5 className="text-white position-absolute mb-0" style={{bottom:"24px",left:"24px"}}>{name.text}</h5>
        </a>
      </div>
  </div>
  )
}

export const MainCategory = ({ slice }) => {
  console.log('MainCategory slice: ', slice)
  const {id, items, primary, slice_type} = slice
  console.log(`MainCategory slice_type: ${slice_type}`)
  if (slice_type !== 'main_category') return null
  const {bg_secondary, title1, desc1} = primary

  return(
    <div className={"py-4" + " " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{title1.text}</h2>
        <h5 className="text-center text-secondary">{desc1.text}</h5>
        <div className="mt-4 row g-4">
          { items.map((item, index) => renderCategory({item, index})) }
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainCategory on PrismicMainpageDataBodyMainCategory {
    id
    slice_type
    items {
      code
      name {
        text
        richText
      }
      image {
        url
      }
    }
    primary {
      bg_secondary
      title1 {
        text
      }
      desc1 {
        text
      }
    }
  }
`
