import * as React from 'react'
import { graphql } from 'gatsby'

export const CallToAction = ({ slice }) => {
  const {bg_secondary, title, subtitle, btn_text, btn_link} = slice.primary

  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5 text-center">
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
        {(btn_text && btn_link.url) &&
          <div className="mt-5">
            <a href={btn_link.url} type="button" className="btn btn-primary">{btn_text}</a>
          </div>
        }
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
    primary {
      bg_secondary
      title
      subtitle
      btn_text
      btn_link {
        url
      }
    }
  }
`
