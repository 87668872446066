import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import { VideoBlogPosts } from '../components/VideoBlogPosts'

import translate from '../utils/translator'

export const MainVideoblog = ({ slice, context }) => {
  console.log('videos: ', context.videos)
  const docs = {
    nodes: context.videos,
  }

  return (
    <div className={"py-4" + " " + (slice.primary.bg_secondary && "bg-light")}>
      <div className="container py-5">
        <h2 className="text-center text-primary">{slice.primary.title1.text}</h2>
        <h5 className="text-center text-secondary">{slice.primary.desc1.text}</h5>
        <VideoBlogPosts docs={docs} />
        <div className="mt-5 text-center">
          <a href="/videoblogs" className="btn btn-primary">{translate('btn_watch_all_videos')}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainVideoblog on PrismicMainpageDataBodyMainVideoblog {
    id
    slice_type
    primary {
      bg_secondary
      title1 {
        text
      }
      desc1 {
        text
      }
    }
  }
`
