import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const FeaturesTwoCols = ({ slice }) => {
  const {bg_secondary, title, subtitle_rt} = slice.primary
  console.log(slice)
  const list = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-md-6">
        {item.list_icon && <div className="mb-2" dangerouslySetInnerHTML={{ __html: item.list_icon }} />}
        <h4 className="">{item.list_title.text}</h4>
		  	<PrismicRichText field={item.list_desc.richText} />
		  </div>
    )
  })
  
  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <div className="text-center">
          <h2>{title}</h2>
          <PrismicRichText field={subtitle_rt.richText} />
        </div>
        <div className="mt-0 row gy-4">
          {list}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturesTwoCols on PrismicPageDataBodyFeaturesTwoCols {
    primary {
      bg_secondary
      title
      subtitle_rt {
        richText
      }
    }
    items {
      list_icon
      list_title {
        text
        richText
      }
      list_desc {
        richText
      }
    }
  }
`
