import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import JotFormReact from 'jotform-react';

export const EmbeddedHtml = ({ slice }) => {
  const {title, embed} = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <JotFormReact formURL="https://form.jotform.com/221142664558356"/>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyEmbeddedHtml on PrismicPageDataBodyEmbeddedHtml {
    primary {
      title
      embed
    }
  }
`
