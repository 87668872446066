import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import ReactPlayer from 'react-player'

export const VideoblogInfo = ({ slice, context }) => {
  console.log('VideoblogInfo slice: ', slice)

  const cntx = context
  const {title} = cntx

  const {primary, slice_type} = slice
  if (slice_type !== 'videoblog_info') return null

  console.log('VideoblogInfo primary: ', primary)
  const {desc, video_url} = primary

  const videoAspectRatio = video_url.width/video_url.height

  return (
    <div>
      <div className="bg-light" style={{backgroundImage:"url(" + video_url.thumbnail_url + ")",backgroundAttachment:"fixed",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000a0",backdropFilter:"blur(4px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{title}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="mt-4">
          <div className="bg-dark ar16-9 d-flex justify-content-center align-items-center">
            <ReactPlayer url={video_url.embed_url} controls="true" width="100%" height="100%"/>
          </div>
          <div className="mt-4">
            <PrismicRichText field={desc.richText} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicBlogItemVideoblogInfo on PrismicBlogItemDataBodyVideoblogInfo {
    id
    slice_type
    primary {
      desc {
        richText
        text
      }
      video_url {
        embed_url
        thumbnail_url
      }
    }
  }
  fragment VbItemVideoblogInfo on PrismicVbItemDataBodyVideoblogInfo {
    id
    slice_type
    primary {
      description {
        richText
        text
      }
      video_url {
        embed_url
        thumbnail_url
      }
    }
  }
`
