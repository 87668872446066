import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const RecipeInfo = ({ slice, context }) => {

  const ingredients = slice.items.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.ingredient}</td>
        <td>{item.amount}</td>
      </tr>
    )
  })

  return (
    <div>
      <div className="row mx-0 border-bottom">
        <div className="col-12 col-md-6 col-lg-5 py-5 d-flex flex-column justify-content-center align-items-center">
          <h1 className="h2">{context.title}</h1>
          <h6>{"Ein Rezept von  " + slice.primary.author}</h6>
        </div>
        <div className="col-12 col-md-6 col-lg-7 p-0">
          <img className="image-cover" style={{maxHeight:"80vh"}} height="" width="" src={slice.primary.img.url} alt={context.title}/>
        </div>
      </div>
      <div className="container py-5">
        <PrismicRichText field={slice.primary.desc.richText} />
        <div className="mt-5">
          <h5 className="mb-4">{"Zutaten"}</h5>
          <table class="table w-auto">
            <tbody>
              {ingredients}
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          <h5 className="mb-4">{"Zubereitung"}</h5>
          <PrismicRichText field={slice.primary.preparation.richText} />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicBlogItemRecipeInfo on PrismicBlogItemDataBodyRecipeInfo {
    id
    slice_type
    slice_label
    primary {
      img {
        url
      }
      author
      desc {
        richText
        text
      }
      preparation {
        richText
      }
    }
    items {
      ingredient
      amount
    }
  }
`
