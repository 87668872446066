import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const OurTeam = ({ slice }) => {
  const {title, subtitle, text, img, img_desc} = slice.primary
  const team = slice.items.map((item, index) => {
    return (
      <div className="col-12 col-md-4" key={index} >
        {(item.name && item.position && item.image.url) &&
        <>
          {/*<div className="bg-light w-100" style={{aspectRatio:"1"}}>
            <img className="image-cover" src={item.image.url} alt="Teammitglied Foto "/>
          </div>*/}
          <div className="mt-0 text-center">
            <h4>{item.name}</h4>
            <h5>{item.position}</h5>
            <h6><a className="text-decoration-none text-body" href={"mailto:" + item.email}>{item.email}</a></h6>
            <h6><a className="text-decoration-none text-body" href={"tel:" + item.phone}>{item.phone}</a></h6>
          </div>
        </>
        }
      </div>
    )
  })

  return (
    <div className={"py-4"}>
      <div className="container py-5">
        <h2 className="text-center">{title}</h2>
        <h5 className="text-center">{subtitle}</h5>
        <PrismicRichText field={text.richText} />
        <img className="w-100 image-cover" src={img.url} alt={img.alt}/>
        <div className="mt-4 text-center">
          <PrismicRichText field={img_desc.richText} />
        </div>
        <div className="mt-0 row gy-4">
          {team}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyOurTeam on PrismicPageDataBodyOurTeam {
    primary {
      title
      subtitle
      text {
        richText
      }
      img {
        url
        alt
      }
      img_desc {
        richText
      }
    }
    items {
      image {
        url
      }
      name
      position
      email
      phone
    }
  }
`
