import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const FeaturesThreeCols = ({ slice }) => {
  const {bg_secondary, title, subtitle} = slice.primary
  console.log(slice)
  const list = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-md-4 text-center">
        <img className="" style={{objectFit:"contain"}} width="96" height="96" src={item.list_image.url}/>
        <h5 className="mt-3">{item.list_title.text}</h5>
		  	<PrismicRichText field={item.list_desc.richText} />
		  </div>
    )
  })
  
  return (
    <div className={"py-4 " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <div className="text-center">
          <h2>{title}</h2>
          <h4>{subtitle}</h4>
        </div>
        <div className="mt-0 row gy-4">
          {list}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturesThreeCols on PrismicPageDataBodyFeaturesThreeCols {
    primary {
      bg_secondary
      title
      subtitle
    }
    items {
      list_image {
        url
      }
      list_title {
        text
        richText
      }
      list_desc {
        richText
      }
    }
  }
`
