import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useLocation } from '@reach/router';

import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import {getDisplaySchedule} from '../utils/utils';
import { tagsData, weekData, categoryData, socialUrls } from '../utils/constants';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import ReserveForm from '../components/ReserveForm';

import translate from '../utils/translator'

const renderMenu = ({menu, index}) => {
  // console.log('menu: ', menu)
  const {title, desc, cost} = menu
  return (
    <div key={index}>
      <div className="d-flex justify-content-between">
        <h6>{title}</h6>
        {cost && <h6>{cost.toFixed(2)} €</h6>}
      </div>
      <div className="text-secondary">
        <PrismicRichText field={desc.richText} />
      </div>
      <hr/>
    </div>
  )
}
const RestaurantDetailMenu = ({ slice, index }) => {
  // console.log('RestaurantDetailMenu slice: ', slice)

  const {id, primary, slice_type, items} = slice
  const {name} = primary

  return (
    <div className="">
      <div className="mt-2">
        <Accordion>
          <Accordion.Item eventKey={index}>
            <Accordion.Header>{name}</Accordion.Header>
            <Accordion.Body>
              { items.map((item, index) => renderMenu({menu: item, index})) }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
    )
}
export const RestaurantDetail = ({slice, slices, context}) => {
  console.log('RestaurantDetail slice: ', slice)
  console.log('context: ', context)
  console.log('slices: ', slices)

  const {id, primary, slice_type} = slice

  const [state, setState] = React.useState({
    nowDay: 0,
    displaySchedule: '',
    isOpen: false,
  });

  const [schedule, setSchedule] = React.useState([])  
  const [tags, setTags] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [socials, setSocials] = React.useState([])

  // menu slices
  const [menu, setMenu] = React.useState([])
  React.useLayoutEffect(() => {
    const menuSlices = slices.filter(item => item.slice_type === 'menu');
    setMenu(menuSlices)
  },[slices])
  //

  React.useLayoutEffect(() => {
    // schedule
    const _schedule = weekData.map(item => {
      const openTime = getDisplaySchedule(slice.primary[item.code])
      return {code: item.code, name: item.name, value: openTime}
    })
    setSchedule(_schedule)
    //
    // tags
    const _tags = tagsData.filter(item => {
      return slice.primary[item.code] === true
    })
    setTags(_tags)
    //
    // categories
    const _categories = categoryData.filter(item => {
      return slice.primary[item.code] === true
    })
    setCategories(_categories)
    //
    // socials
    const _socials = socialUrls.map(item => {
      const value = slice.primary[item.code]
      if (value && value.url) {
        return {code: item.code, img:item.img, url:value.url}
      }
    }).filter(item => item)
    setSocials(_socials)
    //
    const currentWeekDay = moment().isoWeekday() - 1

    let displaySchedule = _schedule[currentWeekDay].value
    if (displaySchedule.length > 13 && displaySchedule != "Ruhetag" && displaySchedule != "24 St. geöffnet") {
      displaySchedule = "";
    } 
    console.log(`currentWeekDay: ${displaySchedule} displaySchedule: ${displaySchedule}`)

    setState(prevState => {
      return {...prevState, nowDay:currentWeekDay, displaySchedule, isOpen:context.isOpen}
    })

  },[slice.primary, context])

  const {address,email,phone,price_type,rest_name,description,coordinates,order_link,reserve_link} = primary

  const {website_url,logo,} = primary

  const images = slice.items || []
  console.log('images: ', images)
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  // todo: rename to restaurant_detail_menu
  const isMenu = slices.filter(item => item.slice_type === 'menu').length > 0
  console.log('isMenu: ', isMenu)

  const galleryImages = images.map((items, index) => {
    const imgUrl = items.image.url;
    return (
      <Carousel.Item key={index} interval={2000} className="ar16-9">
          <Item
            key={index}
            original={imgUrl}
            thumbnail={imgUrl}
            width={2400}
            height={2400}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt={"Restaurantfoto " + (index+1)}
                className="image-cover"
              />
            )}
          </Item>
      </Carousel.Item>
    );
  });


  const isInfo = address || phone || email || website_url.url || (socials.length > 0)

  const MainInfo = () => (
    <div className="rounded-3 bg-light">
      <div style={{height:"48px"}}>
        <img
          className="rounded-circle border border-5 border-white bg-light position-relative mx-auto"
          style={{height:"96px",width:"96px",objectFit:"cover",top:"-48px",left:"50%",transform:"translateX(-50%)"}}
          src={logo.url ? logo.url : "/default-rest-logo.png"}
          alt={rest_name + " Logo"}
        />
      </div>
      <div className={"p-4 pt-2 " + (isInfo && "border-bottom")}>
        <h5 className="text-center">{rest_name}</h5>
        <div className="row gx-4 gy-1 fw-600 d-flex justify-content-center">
          {categories.map((item,index) => (
              <div key={index} className="col-auto">
                <a className="text-decoration-none text-secondary" href={`/search/?categories=${item.code}`}>{item.name}</a>
              </div>
          ))}
        </div>
      </div>
      {/*<div className="border-bottom p-4 d-block d-lg-none">
        <div className="row g-2 justify-content-center">
          <div className="col-auto"><a href={"#"} type="button" className="btn btn-primary">Zum Abholung Bestellen</a></div>
          <div className="col-auto"><a href={"#"} type="button" className="btn btn-primary">Tisch Reservieren</a></div>
        </div>
      </div>*/}
      {isInfo &&
        <div className="p-4">
          {/*<h6 className="text-center">{translate('info_about_rest')}</h6>*/}
          <div className="fw-500 fs-0875">
            {address && 
              <><a className="text-decoration-none d-flex align-items-center" href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude + "," + coordinates.longitude) : "javascript:void(0);"} target="_blank"><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`} alt={"Restaurantstandort Icon"}/>{address}</a><hr/></>
            }
            {phone && 
              <><a className="text-decoration-none d-flex" href={"tel:" + phone}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/phone.svg`} alt={"Restaurant Telefon Icon"}/>{phone}</a><hr/></>
            }
            {email && 
              <><a className="text-decoration-none d-flex" href={"mailto:" + email}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/email.svg`} alt={"Restaurant Email Icon"}/>{email}</a><hr/></>
            }
            {website_url.url && 
              <><a className="text-decoration-none d-flex" href={website_url.url} target="_blank"><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/web.svg`} alt={"Restaurant Webseite Icon"}/>{translate('btn_website')}</a><hr/></>
            }
            {!!socials.length && (
              <div className="mt-2 row g-2">
                {socials.map((item, index) => (
                  <div key={index} className="col-auto">
                    <a href={item.url} target="_blank" type="button" className="btn btn-white">
                      <img className="details-icon" height="24" width="24" src={item.img} alt={"Soziales Web Icon " + (index+1)}/>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )

  const Schedule = () => (
    <div className="rounded-3 bg-light mt-4 p-4 schedule">
      <Accordion flush>
          <Accordion.Header>
            <div className="d-flex flex-fill justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className={"text-success bg-opacity-25 d-flex justify-content-center align-items-center me-2 " + (state.isOpen ? "bg-success" : "bg-danger")} style={    {height:"32px",width:"32px",  borderRadius:"40%"}}>
                  <img className={state.isOpen ? "schedule-open-icon" : "schedule-closed-icon"} height="24" width="24" src={`/icons/schedule.svg`} alt="Restaurant Status Icon"/>
                </div>
                <div>{state.isOpen ? translate('schedule_open') : translate('schedule_closed')}</div>
              </div>
              <div>{state.displaySchedule}</div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="mt-3 gy-2 fs-0875">
              {schedule.map((item, index) => (
                <div key={index} className={"d-flex rounded overflow-hidden mb-2 " + ((state.nowDay == index && state.isOpen) ? "bg-success bg-opacity-25 fw-600" : ((state.nowDay == index && !state.isOpen) ? "bg-danger bg-opacity-25 fw-600" : "bg-white text-secondary"))}>
                  <div>
                    <div className={"h-100 " + ((state.nowDay == index && state.isOpen) ? "bg-success" : ((state.nowDay == index && !state.isOpen) ? "bg-danger" : "bg-secondary bg-opacity-25"))} style={{width:"6px"}} />
                  </div>
                  <div className={"w-100 d-flex justify-content-between px-3 "+ ((state.nowDay == index) ? "p-3" : "p-2")}>
                    <div className="">{item.name}:</div>
                    <div> {item.value}  </div>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Body>
      </Accordion>
    </div>
  )

  const events = context.links_to_events || []
  console.log('events: ', events)
  const now = moment()
  const filteredEvents = events.filter((item, index) => {
    if (item.linked_event.document) {
      const {start_time, finish_time} = item.linked_event.document.data
      const startTime = moment(start_time)
      const finishTime = moment(finish_time)
      return ((startTime < now) && (finishTime > now))
    }
  })
  const eventsInfo = filteredEvents.map((item, index) => {
    console.log('item: ', item)
    const {title} = item.linked_event.document.data
    console.log('title: ', title)

    const eventBody = item.linked_event.document.data.body || []
    console.log('event body: ', eventBody)
    const eventInfo = eventBody.find(item => item.slice_type === 'event_info') || {}
    const eventItems = eventInfo.items
    let eventUrl = "/default-rest-logo.png"
    if (eventItems && eventItems.length) {
      const _url = eventItems[0].image?.url
      if (_url) eventUrl = _url
    }
    console.log('eventUrl: ', eventUrl)

    return (
    <div key={index} className="d-flex mt-3">  
      <div className="bg-dark rounded-3 overflow-hidden me-3 " style={{height:"80px",width:"80px"}}>
        <a href={item.linked_event.url}><img className="image-cover" src={eventUrl}/></a>
      </div>
      <a className="h6 text-body" href={item.linked_event.url}>{title.text}</a>
    </div>)
  })

  const videoblogs = context.links_to_videoblogs || []
  const videoblogsInfo = videoblogs.map((item, index) => {
    const {title} = item.linked_videoblog.document.data
    
    return (
    <div key={index} className="d-flex mt-3">  
      <div className="bg-dark rounded-3 overflow-hidden me-3 " style={{height:"80px",width:"80px"}}>
        <a href={item.linked_videoblog.url}><img className="image-cover" src={item.linked_videoblog.document.data.body[0].primary.video_url.thumbnail_url}/></a>
      </div>
      <a className="h6 text-body" href={item.linked_videoblog.url}>{title.text}</a>
    </div>)
  })

  /*modal*/
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const shareIcon = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img className="ms-2 icon-white" height="20" width="20" src={`/icons/share.svg`} alt="Teilen Icon"/>
    </a>
  ));

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }
  const {reserve_form} = primary
  let isReserveForm = true;
  if (reserve_form === false) {
    isReserveForm = false;
  }

  return (
    <div>
      <div className="bg-light " style={{backgroundImage:"url(" + (isImages && slice.items[0].image.url) + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(2px)"}}>
          <div className="container py-5">
              <div className="d-flex align-items-center mb-2">
                <h1 className="h3 text-white mb-0">{rest_name}</h1>
                <Dropdown>
                  <Dropdown.Toggle as={shareIcon} id="dropdown-share"/>
                  <Dropdown.Menu className="share">
                    <Dropdown.Item className="p-0 text-center">
                      <FacebookShareButton className="w-100" url={useLocation().href} quote={rest_name}>
                        <img className="my-2 details-icon" height="18" width="18" src={`/icons/facebook.svg`} alt={"Facebook Icon"}/>
                      </FacebookShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 text-center">
                      <TwitterShareButton className="w-100" url={useLocation().href} title={rest_name}>
                        <img className="my-2 details-icon" height="18" width="18" src={`/icons/twitter.svg`} alt={"Twitter Icon"}/>
                      </TwitterShareButton>
                    </Dropdown.Item>
                    <Dropdown.Item className="p-0 text-center">
                      <WhatsappShareButton className="w-100" url={useLocation().href} title={rest_name}>
                        <img className="my-2 details-icon" height="18" width="18" src={`/icons/whatsapp.svg`} alt={"WhatsApp Icon"}/>
                      </WhatsappShareButton>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="row gx-4 gy-1 fw-600">
                {categories.map((item,index) => (
                  <div key={index} className="col-auto">
                    <a className="text-decoration-none text-light" href={`/search/?categories=${item.code}`}>{item.name}</a>
                  </div>
                ))}
              </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
              <div className="row gx-4 gy-2 mt-0">
                {coordinates && <div className="col-auto"><a href={("http://maps.google.com/maps/place/" + coordinates.latitude + "," + coordinates.longitude)} target="_blank" className="text-white"><img className="me-1 icon-white" height="18" width="18" src={`/icons/location.svg`} alt={"Restaurantstandort Icon"}/>{translate('route')}</a></div>}
                {phone && <div className="col-auto"><a href={"tel:" + phone} className="text-white"><img className="me-1 icon-white" height="18" width="18" src={`/icons/phone.svg`} alt={"Restaurant Telefon Icon"}/>{phone}</a></div>}
                {isMenu && <div className="col-auto"><a href={"#menu"} className="text-white"><img className="me-1 icon-white" height="18" width="18" src={`/icons/menu.svg`} alt={"Restaurant Menü Icon"}/>{translate('menu')}</a></div>}
              </div>
              <div className="row g-2 mt-2 mt-md-0">
                <div className="col-auto"><a id="order-now" onClick={order_link.url ? undefined : handleShow } href={order_link.url} target={order_link.url && "_blank"} type="button" className="btn btn-primary">{translate('btn_order_delivery')}</a></div>
                <div className="col-auto"><a id="table-reserve" /*onClick={reserve_link.url ? undefined : handleShow} href={reserve_link.url}*/ href="#reserve-form-container" target={reserve_link.url && "_blank"} type="button" className="btn btn-primary">{translate('btn_book_table')}</a></div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>Das Tischreservierungssystem und unser Bestellshop werden noch eingerichtet.<br/>Gerne nehmen wir Ihre Anfrage per Telefon an!</Modal.Body>
                  <Modal.Footer>
                    <a onClick={handleClose} type="button" className="btn btn-primary">Ok</a>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row mt-4">
          <div className="col-lg-8">
            <div className="d-block d-lg-none mb-4">
              <MainInfo/>
            </div>        
            {!!tags.length && (
              <div className="rounded-3 bg-light p-4 my-4 mt-lg-0">
                <div className="row g-2 mt-1">
                  {tags.map((item,index) => (
                    <div key={index} className="col-auto">                    
                      <a href={`/search/?tags=${item.code}`} type="button" className="btn btn-white btn-keyword">
                        <img className="details-icon me-2" height="24" width="24" src={item.img} alt={"Tag Icon " + (index+1)}/>
                        <span>#{item.name}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!!schedule.length && (
              <div className="d-block d-lg-none mb-4">
                <Schedule/>
              </div>
            )}
            {isImages && (
              <div className="bg-light ar16-9 " style={{cursor:"pointer"}}>
                <Gallery>
                  <Carousel controls={(galleryImages.length > 1) ? true : false} indicators={(galleryImages.length > 1) ? true : false}>
                    {galleryImages}
                  </Carousel>
                </Gallery>
              </div>
            )}
            <div className="mt-4">
              <h5>{translate('description')}</h5>
              <div className="lh-175">
                <PrismicRichText field={description.richText} />
              </div>
            </div>
            {isReserveForm && (<div id="reserve-form-container" className="mt-4 p-4 rounded-3 border overflow-hidden bg-light">
              <h6>{translate('reserve_form_title')}</h6>
              <p>{translate('reserve_form_text')}</p>
              <ReserveForm formId={"sifi-eats-reserve-form"} pageUrl={pageUrl}/>
            </div>)}
            {isMenu && <div id="menu" className="mt-5"><h5>{translate('menu')}</h5></div>}
            {menu.map((item, index) => <RestaurantDetailMenu key={index} slice={item} index={index} /> )}
            {(address || coordinates) &&
              <div className="mt-5">
                <h5>{translate('location')}</h5>
                {address && (
                  <a
                    className="text-decoration-none text-secondary d-flex align-items-center"
                    href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude +   "," + coordinates.longitude) : "javascript:void(0);"}
                    target="_blank"
                  >
                    <img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`} alt={"Restaurantstandort Icon"}/>
                    {address}
                  </a>
                )}
                {coordinates &&
                  <div className="mt-2">
                    <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
                      <GoogleMap
                        mapContainerStyle={{width:"100%",height:"400px"}}
                        center={{lat:coordinates.latitude,lng:coordinates.longitude}}
                        zoom={17}
                        options={{fullscreenControl:false,mapTypeControl:false,streetViewControl:false,styles:[{"featureType":"poi","stylers":[{"visibility":"off"}]}]}}
                      >
                        <Marker
                          position={{
                            lat: coordinates.latitude,
                            lng: coordinates.longitude
                          }}                     
                        >
                        </Marker>
                      </GoogleMap>
                    </LoadScript>
                  </div>
                }
              </div>
            }         
          </div>
          <div className="col-lg-4 details-block">
            <div className="sticky-top" style={{top:"48px"}}>
              <div className="rounded-3 bg-light d-none d-lg-block">
                <MainInfo/>
              </div>
              {!!schedule.length && (
                <div className="d-none d-lg-block">
                  <Schedule/>
                </div>
              )}              
              {!!eventsInfo.length &&
                <div className="rounded-3 bg-light p-4 mt-4">
                  <h6>{translate('events')}</h6>
                  {eventsInfo}
                </div>
              }
              {!!videoblogsInfo.length &&
                <div className="rounded-3 bg-light p-4 mt-4">
                  <h6>{translate('videoblogs')}</h6>
                  {videoblogsInfo}
                </div>
              }
              {/*!!tags.length && (
                <div className="rounded-3 bg-light mt-4 p-4">					
                  <div className="d-flex align-items-center">
                    <div className={"text-success bg-opacity-25 d-flex justify-content-center align-items-center me-2 bg-info"} style={{height:"32px",width:"32px",     borderRadius:"40%"}}>
                      <img className="tags-icon" height="22" width="22" src={`/icons/tag.svg`} alt=""/>
                    </div>
                    <div>Stichworte</div>
                  </div>
                  <div className="row g-2 mt-1">
                    {tags.map((item,index) => (
                      <div key={index} className="col-auto">
                        <a href={`/search/?tags=${item.code}`} type="button" className="btn btn-white btn-keyword">#{item.name}</a>
                      </div>
                    ))}
                  </div>
                </div>
              )*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment RestaurantDetail on PrismicRestaurantDetailDataBodyRestaurantDetail {
    id
    slice_type
    primary {
      reserve_form
      rest_name
      phone
      email
      address
      price_type
      #categories
      american
      asian
      bar
      german
      greek
      indian
      international
      italian
      portuguese
      street_food
      sushi
      turkish
      vegan

      #schedule
      sunday_time
      monday_time
      tuesday_time
      wednesday_time
      thursday_time
      friday_time
      saturday_time
      
      #tags
      outdoor_seats
      beer_garden
      kid_friendly
      free_wifi
      delivery
      online_order
      parking_spaces
      wheelchair_accessible
			city_voucher
      daily_menu
      terrace

      description {
        text
        richText
      }
      coordinates {
        latitude
        longitude
      }
      facebook_url {
        url
      }
      instagram_url {
        url
      }
      twitter_url {
        url
      }
      youtube_url {
        url
      }
      website_url {
        url
      }
      logo {
        url
      }
      order_link {
        url
      }
      reserve_link {
        url
      }
    }
    items {
      image {
        url
      }
    }
  }
`
