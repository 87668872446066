import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import { asText } from '@prismicio/helpers'
import {Restaurant} from '../components/Restaurant'

import {isRestaurantOpen} from '../utils/utils'

import translate from '../utils/translator'

const renderRest = ({item, index}) => {
  // console.log('renderRest item: ', item)

  // restaurant
  const rest = item.data.body.find(item => item.slice_type === 'restaurant_detail')
  const {uid} = item
  console.log(`uid: ${uid}`)
  console.log('rest: ', rest)
  const {rest_code, moday_time, tuesday_time} = rest.primary

  return (
    <div key={index}>
      <ul>
        <li>{moday_time}</li>
        <li>{tuesday_time}</li>
      </ul>
    </div>
  )
}

const shuffleArray = (rests) => {
  const allRest = rests.map(item => {
    item.isOpen = isRestaurantOpen({restItem:item})
    return item
  });
  // .filter(item => item.data.priority !== null);
  const opened = allRest.filter(item => item.isOpen)
  const closed = allRest.filter(item => !item.isOpen) || []
  let _someRest = allRest.sort( () => Math.random() - 0.5)
  if (opened && opened.length) {
    _someRest = opened.sort( () => Math.random() - 0.5)
    const shuffled = closed.sort( () => Math.random() - 0.5)
    _someRest = opened.concat(shuffled)
  }
  const someRest = _someRest.slice(0,3)
  console.log('some 3 rest: ', someRest)
  return someRest
}

export const MainRestourants = ({ slice, context }) => {
  console.log('MainRestourants slice: ', slice)
  console.log('MainRestourants context: ', context)
  
  const [shuffled,setShuffled] = React.useState([])
  React.useLayoutEffect(() => {
    // useLayoutEffect: If you need to mutate the DOM and/or do need to perform measurements
    setShuffled(shuffleArray(context.rests));
  }, [context.rests])

  // slice from main page
  const {id, items, primary, slice_type} = slice
  // fragment(Non-repeatable zone) title and desc
  const {bg_secondary,title1, desc1, button} = primary
  // Repeatable zone for STUB only!
  // items.foreatch(item => {
  //   const {lang, desc, image} = item // not used
  // })

  
  console.log('shuffled: ', shuffled)

  return (
    <div className={"py-4" + " " + (bg_secondary && "bg-light")}>
      <div className="container py-5">
        <div className="">
          <h2 className="text-center text-primary">{title1.text}</h2>
          <h5 className="text-center text-secondary">{desc1.text}</h5>
        </div>
        <div className="mt-4 row g-4 justify-content-center">
          { // Repeatable zone
            shuffled.map((item, index) => {
              return (
                <div className="col-12 col-md-4" key={index}>
                  <Restaurant restaurant={item} />
                </div>
              )
            })
          }
        </div>
        <div className="mt-5 text-center">
          <a href="/search" className="btn btn-primary">{translate('btn_show_all')}</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MainpageMainRestourants on PrismicMainpageDataBodyMainRestourants {
    id
    slice_type
    primary {
      bg_secondary
      button {
        id
      }
      desc1 {
        text
      }
      title1 {
        text
      }
    }
  }
`
