import * as React from 'react'
import { graphql } from 'gatsby'

export const PageCover = ({ slice }) => {
  const {cover_title, cover_subtitle, cover_image, btn_text, btn_link} = slice.primary

  return (
    <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + cover_image.url + ")",backgroundPosition:"center center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <div className="py-5" style={{backgroundColor:"rgba(0, 0, 0, 0.75)"}}>
        <div className="position-relative container py-5">
          <div className="py-5">
            <div className="py-5 text-center">
              <h1 className="text-white">{cover_title}</h1>
              <h2 className="text-white mt-3">{cover_subtitle}</h2>
              {(btn_text && btn_link.url) &&
                <div className="mt-5">
                  <a href={btn_link.url} type="button" className="btn btn-lg btn-primary">{btn_text}</a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyPageCover on PrismicPageDataBodyPageCover {
    primary {
      cover_title
      cover_subtitle
      cover_image {
        url
      }
      btn_text
      btn_link {
        url
      }
    }
  }
`
