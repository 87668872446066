import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

import translate from '../utils/translator'
import SimpleContactForm from '../components/SimpleContactForm';

export const RentalInfo = ({ slice, context }) => {
  console.log('RentalInfo slice: ', slice)
  // const {id, primary, slice_type} = slice;
  if (!slice.slice_type === 'rental_info') return null;
  const {title, restLink} = context
  const {uid, document} = restLink
  const _restLink = `/restaurant/${uid}`
  const restDetail = document?.data?.body?.find(item => item.slice_type === 'restaurant_detail')
  const restName = restDetail?.primary?.rest_name
  const restLogo = restDetail?.primary?.logo.url
  const {description, area, persons, email, phone, address, coordinates} = slice.primary
  const isInfo = phone || email || address

  const images = slice.items || []
  console.log('images: ', images)
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  const galleryImages = images.map((items, index) => {
    const imgUrl = items.image.url;
    return (
      <Carousel.Item key={index} interval={2000} className="ar16-9">
          <Item
            key={index}
            original={imgUrl}
            thumbnail={imgUrl}
            width={2400}
            height={2400}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt=""
                className="image-cover"
              />
            )}
          </Item>
      </Carousel.Item>
    );
  });

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }  

  return (
    <div>
      <div className="bg-light" style={{backgroundImage:"url(" + slice.items[0].image.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <div className="py-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{title}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <a href="/veranstaltungsraum-sindelfingen-mieten/" className="link-back p-2 ps-0 text-decoration-none"><img className="me-2 details-icon" height="28" width="28" src={`/icons/back.svg`}/>{translate('back')}</a>
        <div className="pt-5">
          <div className="row">
            <div className="col-lg-8 order-2 order-lg-1">
              {isImages && (
                <div className="bg-light ar16-9 " style={{cursor:"pointer"}}>
                  <Gallery>
                    <Carousel controls={(galleryImages.length > 1) ? true : false} indicators={(galleryImages.length > 1) ? true : false}>
                      {galleryImages}
                    </Carousel>
                  </Gallery>
                </div>
              )}
              <h4 className="mt-4">{context.title}</h4>
              <div className="row gy-2 px-2">              
                {area &&
                  <div className="col-auto bg-light rounded-3 me-2 p-2 px-3">
                    <img className="details-icon me-2" height="20" width="20" src={`/icons/area.svg`} alt=""/>
                    <span className="h6 fs-0875 mb-0">{translate('area')}: {area} qm</span>
                  </div>
                }
                {persons &&
                  <div className="col-auto bg-light rounded-3 p-2 px-3">
                    <img className="details-icon me-2" height="20" width="20" src={`/icons/persons.svg`} alt=""/>
                    <span className="h6 fs-0875 mb-0">{translate('persons')}: {persons}</span>
                  </div>
                }
              </div>
              <div className="mt-4">
                <PrismicRichText field={description.richText} />
              </div>
              {(address || coordinates) &&
                <div className="mt-5">
                  <h5>{translate('location')}</h5>
                  {address && (
                    <a
                      className="text-decoration-none text-secondary d-flex align-items-center"
                      href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude +   "," + coordinates.longitude) : "javascript:void(0);"}
                      target="_blank"
                    >
                      <img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`} alt=""/>
                      {address}
                    </a>
                  )}
                  {coordinates &&
                    <div className="mt-2">
                      <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
                        <GoogleMap
                          mapContainerStyle={{width:"100%",height:"400px"}}
                          center={{lat:coordinates.latitude,lng:coordinates.longitude}}
                          zoom={17}
                          options={{fullscreenControl:false,mapTypeControl:false,streetViewControl:false,styles:[{"featureType":"poi","stylers":[{"visibility":"off"}]}]}}
                        >
                          <Marker
                            position={{
                              lat: coordinates.latitude,
                              lng: coordinates.longitude
                            }}                     
                          >
                          </Marker>
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  }
                </div>
              }
            </div>
            <div className="col-lg-4 order-1 order-lg-2 mb-4 mb-lg-0">
              <div className="rounded-3 bg-light">
                {isInfo &&
                  <div className="p-4">
                    {restName && 
                      <div className="d-flex align-items-center mb-4">
                        <a href={_restLink}><img className="rounded-circle bg-light position-relative" style={{height:"48px",width:"48px"}} src={restLogo ? restLogo : "/default-rest-logo.png"}  alt='' /></a>
                        <a href={_restLink} className="h5 text-decoration-none text-body mb-0 ms-2">{restName}</a>
                      </div>
                    }
                    {/*<h6 className="text-center">Information</h6>*/}
                    <div className="mt-3 fw-500 fs-0875">
                      {address && 
                        <><a className="text-decoration-none d-flex align-items-center" href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude + "," +    coordinates.longitude) : "javascript:void(0);"} target="_blank"><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/location.svg`}/>  {address}</a><hr/></>
                      }
                      {email && 
                        <><a className="text-decoration-none d-flex" href={"mailto:" + email}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/email.svg`}/>{email}</a><hr/></>
                      }
                      {phone && 
                        <><a className="text-decoration-none d-flex" href={"tel:" + phone}><img className="mb-1 me-2 details-icon" height="20" width="20" src={`/icons/phone.svg`}/>{phone}</a><hr/></>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="mt-4 p-4 sticky-top rounded-3 border overflow-hidden">
                <SimpleContactForm formId={"sifi-eats-reserve-form"} pageUrl={pageUrl}/>
              </div>       
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicRentalItemRentalInfo on PrismicRentalItemDataBodyRentalInfo {
    id
    slice_type
    slice_label
    primary {
      description {
        richText
        text
      }
      area
      persons
      email
      phone
      address
      coordinates {
        latitude
        longitude
      }
    }
    items {
      image {
        url
      }
    }
  }
`