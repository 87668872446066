import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import Accordion from 'react-bootstrap/Accordion';

// const renderMenu = ({menu, index}) => {
//   // console.log('menu: ', menu)
//   const {title, desc, cost} = menu
//   return (
//     <div key={index}>
//       <div className="d-flex justify-content-between">
//         <h6>{title}</h6>
//         {cost && <h6>{cost.toFixed(2)} €</h6>}
//       </div>
//       <div className="text-secondary">
//         <PrismicRichText field={desc.richText} />
//       </div>
//       <hr/>
//     </div>
//   )
// }

export const RestaurantDetailMenu = ({ slice, index }) => {
  // console.log('RestaurantDetailMenu slice: ', slice)

  const {id, primary, slice_type, items} = slice
  const {name} = primary

  return(<></>)

  // return (
  //   <div className="container">
  //     <div className="mt-2 col-12 col-lg-8 pe-lg-2">
  //       <Accordion>
  //         <Accordion.Item eventKey={index}>
  //           <Accordion.Header>{name}</Accordion.Header>
  //           <Accordion.Body>
  //             { items.map((item, index) => renderMenu({menu: item, index})) }
  //           </Accordion.Body>
  //         </Accordion.Item>
  //       </Accordion>
  //     </div>
  //   </div>
  //   )
}

export const query = graphql`
  fragment RestaurantDetailMenu on PrismicRestaurantDetailDataBodyMenu {
    id
    slice_type
    primary {
      name
    }
    items {
      cost
      desc {
        richText
      }
      title
    }
  }
`
