import * as React from 'react'
import { useState } from 'react';
import { graphql } from 'gatsby'
import SimpleContactForm from '../components/SimpleContactForm';

export const ContactForm = ({ slice }) => {
  const {title} = slice.primary

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }  
  
  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="col-12 col-md-9 col-lg-7 mx-auto">
          {title &&<h2 className="text-center mb-5">{title}</h2>}
          <SimpleContactForm formId={"sifi-eats-simple-form"} pageUrl={pageUrl}/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm on PrismicPageDataBodyContactForm {
    primary {
      title
    }
  }
`
