//
export const tagsData = [
  {code: 'outdoor_seats', name: 'Außenplätze', img: '/icons/outdoor-seats.svg'},
  {code: 'beer_garden', name: 'Biergarten', img: '/icons/beer.svg'},
  {code: 'kid_friendly', name: 'Kinderfreundlich', img: '/icons/child.svg'},
  {code: 'free_wifi', name: 'Kostenloses WLAN', img: '/icons/wifi.svg'},
  {code: 'delivery', name: 'Lieferung', img: '/icons/delivery.svg'},
  {code: 'online_order', name: 'Online bestellen', img: '/icons/online-order.svg'},
  {code: 'parking_spaces', name: 'Parkplätze für Kunden', img: '/icons/car.svg'},
  {code: 'wheelchair_accessible', name: 'Rollstuhlgerecht', img: '/icons/wheelchair.svg'},
  {code: 'city_voucher', name: 'Stadtgutschein', img: '/icons/coupon.svg'},
  {code: 'daily_menu', name: 'Tageskarte', img: '/icons/menu.svg'},
  {code: 'terrace', name: 'Terrasse', img: '/icons/terrace.svg'},
]
export const weekData = [
  {code: 'monday_time',name: 'Montag',},
  {code: 'tuesday_time',name: 'Dienstag',},
  {code: 'wednesday_time',name: 'Mittwoch',},
  {code: 'thursday_time',name: 'Donnerstag',},
  {code: 'friday_time',name: 'Freitag',},
  {code: 'saturday_time',name: 'Samstag',},
  {code: 'sunday_time',name: 'Sonntag',},
]
export const categoryData = [
  {code: 'american', name: 'Amerikanisch',},
  {code: 'asian', name: 'Asiatisch',},
  {code: 'bar', name: 'Bar',},
  {code: 'german', name: 'Deutsch',},
  {code: 'greek', name: 'Griechisch',},
  {code: 'indian', name: 'Indisch',},
  {code: 'international', name: 'International',},
  {code: 'italian', name: 'Italienisch',},
  {code: 'portuguese', name: 'Portugiesisch',},
  {code: 'street_food', name: 'Street Food',},
  {code: 'sushi', name: 'Sushi',},
  {code: 'turkish', name: 'Türkisch',},
  {code: 'vegan', name: 'Vegan',},
]
export const socialUrls = [
  {code: 'facebook_url', img: '/icons/facebook.svg',},
  {code: 'instagram_url', img: '/icons/instagram.svg',},
  {code: 'twitter_url', img: '/icons/twitter.svg',},
  {code: 'youtube_url', img: '/icons/youtube.svg',},
]
