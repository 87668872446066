import * as React from 'react'
import { Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'
import { Pagination } from '../utils/pagination'
import { useLocation } from '@reach/router';

import translate from '../utils/translator'

// Function to retrieve a small preview of the post's text
const firstParagraph = (body) => {
  // // Find the first text slice of post's body
  console.log('VideoBlogPosts first slice :', body[0])
  const firstTextSlice = body.find((slice) => slice.slice_type === 'videoblog_info')
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    const text = prismicH.asText(firstTextSlice.primary.description.richText)
    const limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</p>
      )
    }
    // If it's shorter than the limit, just show it normally
    return <p>{text}</p>
  }
  // If there are no slices of type 'text', return nothing
  return null
}

// A summary of the Blog Post
const PostSummary = ({ post, id }) => {
  const {data, uid, url} = post
  console.log('PostSummary: ', data)
  const {date, title, body} = data
  console.log('video_url :', data.body[0].primary.video_url)

  let showType = true
  if (useLocation().pathname == "/videoblogs") {
    showType = false
  }

  return (
    <div className="col-lg-4">
      <div className="overflow-hidden bg-white h-100 d-flex flex-column">
        <div className="bg-dark position-relative rounded-3 overflow-hidden" style={{height:"200px"}}>
          <a href={url}><img className="image-cover" src={data.body[0].primary.video_url.thumbnail_url} alt={title.text + " Titelfoto"}/></a>
          {showType && <a href={"/videoblogs"} className="btn btn-sm btn-primary position-absolute fs-075" style={{bottom:"16px",left:"16px"}}>{translate('btn_videoblog')}</a>}
        </div>
        <div className="d-flex flex-fill flex-column justify-content-between">
          <div className="pt-3 flex-fill">
            <div className="d-flex fs-0875 fw-600">
              <a href={url} className="text-decoration-none text-secondary d-flex align-items-center me-2"><img className="me-1 details-icon" height="16" width="16" src={`/icons/event-start.svg`} alt={"Videoblog Datum Icon"}/>{date}</a>
            </div>
            <a className="text-decoration-none text-body" href={url}><h6 className="mt-2 mb-0">{title.text}</h6></a>
          </div>
				</div>
      </div>
    </div>
  )
}

export const VideoBlogPosts = ({ docs, context }) => {
  if (!docs) return null

  const posts = docs.nodes
  // console.log('VideoBlogPosts posts: ', posts)
  const total = context ? context.total : 0
  const isPagin = total && (total.length > 1)
  console.log('total video pages: ', total)

  return (
    <div className="mt-4 row g-4">
      {posts.map((post, index) => (
        <PostSummary post={post} key={index} />
      ))}
      {!!isPagin && <Pagination pageInfo={docs.pageInfo} base={'videoblogs'}/>}
    </div>
  )
}
