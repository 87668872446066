import * as React from 'react'
import { Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'
import { Pagination } from '../utils/pagination'
import { useLocation } from '@reach/router';

import translate from '../utils/translator'


const firstParagraph = (desc) => {
  if (desc != null) {
    const textLimit = 120
    const text = desc.text
    const limitedText = text.substring(0, textLimit)
    if (text.length > textLimit) {
      return (
        <>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</>
      )
    }
    return <>{text}</>
  }
  return null
}

// A summary of the Blog Post
const PostSummary = ({ post, id }) => {
  const {data, uid, url} = post
  console.log('PostSummary: ', data)
  const {date, title, type} = data
  let btnType = ""
  let blogImg = ""
  if (type == "blog") {
    btnType = "btn_blog"
    blogImg = data.body[0].primary.img.url
  } else if (type == "recipe") {
    btnType = "btn_recipe"
    blogImg = data.body[0].primary.img.url
  } else if (type == "videoblog") {
    btnType = "btn_videoblog"
    blogImg = data.body[0].primary.video_url.thumbnail_url
  }

  return (
    <a href={url} className="bg-white text-body col-lg-4">
      <div className="bg-dark position-relative rounded-3 overflow-hidden" style={{height:"200px"}}>
        <img className="image-cover" src={blogImg} alt={title}/>
        <button className="btn btn-sm btn-primary position-absolute fs-075" style={{bottom:"16px",left:"16px"}}>{translate(btnType)}</button>
      </div>
      <h6 className="mt-3">{title}</h6>
      <div className="fs-0875 fw-600 text-decoration-none text-secondary d-flex align-items-center mt-2" style={{lineHeight:"1"}}><img className="me-1 details-icon" height="16" width="16" src={`/icons/event-start.svg`} alt={"Videoblog Datum Icon"}/>{date}</div>
      <div className="mt-2 text-secondary fs-0875">{firstParagraph(data.body[0].primary.desc)}</div>
    </a>
  )
}

export const BlogPosts = ({ docs }) => {
  if (!docs) return null

  const posts = docs.nodes


  return (
    <div className="row g-4">
      {posts.map((post, index) => (
        <PostSummary post={post} key={index} />
      ))}
    </div>
  )
}
